import React, { Component } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  withStyles
} from '@material-ui/core';
import { GitHub, Layers } from '@material-ui/icons';
import GithubAvatar from '../common/GithubAvatar';
import { Link as RouterLink } from 'react-router-dom';
import { grey } from '@material-ui/core/colors';
import { UserContext } from '../../config/UserContext';
import { CancelToken } from 'axios';
import axios from '../../api/axios';
import LoadingSpinner from '../common/LoadingSpinner';
import { Alert } from '@material-ui/lab';
import config from '../../config';

const styles = {
  alert: {
    marginBottom: 20,
  },
  github: {
    color: grey[800],
  },
  link: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  manage: {
    textDecoration: 'none',
  }
};

class Installations extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadingFailed: false,
      installations: [],
    };
  }

  async componentDidMount() {
    this.context.changeActiveInstallation(null);

    await this.fetchInstallations();

    this.context.setAvailableInstallations(this.state.installations);
  }

  componentWillUnmount() {
    this.source?.cancel();
    this.setState = () => {
    };
  }

  async fetchInstallations() {
    try {
      this.setState({ loading: true, loadingFailed: false });

      this.source = CancelToken.source();

      const { data } = await axios.get('/installation', {
        cancelToken: this.source.token
      });

      this.setState({ installations: data.installations });
    } catch (err) {
      this.setState({ loadingFailed: true });
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const githubLink = installation => ({
      pathname: installation.accountType === 'Organization'
        ? 'https://github.com/organizations/' + installation.accountLogin + '/settings/installations/' + installation.id
        : 'https://github.com/settings/installations/' + installation.id
    });

    if (this.state.loading) return (
      <LoadingSpinner/>
    );

    if (this.state.loadingFailed) return (
      <Grid container>
        <Grid item xs={12}>
          <Alert severity="error">Cannot load installations. Please contact us.</Alert>
        </Grid>
      </Grid>
    );

    if (this.state.installations.length === 0) return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid xs={8}>
              <Alert severity="info" className={this.props.classes.alert}>
                No installations found, or you don't have sufficient permissions to manage any installation.
                Please click below button to install Naming Conventions Bot integration in your organisation.
              </Alert>
              <Box textAlign="center">
                <Link
                  href={'https://github.com/marketplace/' + config.GITHUB_APP}
                  className={this.props.classes.link}
                >
                  <Button variant="contained" startIcon={<GitHub/>} size="large" color="primary">
                    Install integration
                  </Button>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );

    return (
      <Grid container>
        <Grid item xs={12}>
          <Card elevation={2}>
            <CardHeader avatar={<Layers/>} title="Installations" titleTypographyProps={{ variant: 'h6' }}/>
            <CardContent>
              {this.state.installations.length > 0 && this.state.installations.map(installation =>
                <List key={installation.id}>
                  <ListItem>
                    <ListItemAvatar>
                      <GithubAvatar url={installation.accountAvatarUrl} variant="normal"/>
                    </ListItemAvatar>
                    <ListItemText primary={installation.accountLogin} secondary={'Type: ' + installation.accountType}/>
                    {installation.manageable && <RouterLink
                      to={githubLink(installation)}
                      className={this.props.classes.github}
                      target="_blank"
                    >
                      <IconButton aria-label="Github" color="inherit">
                        <GitHub/>
                      </IconButton>
                    </RouterLink>}
                    {installation.manageable ?
                      <RouterLink to={'/installations/' + installation.id} className={this.props.classes.manage}>
                        <Button variant="contained" color="primary">Manage</Button>
                      </RouterLink> :
                      <Tooltip arrow title="Only organization admins can manage naming conventions." placement="left">
                        <span>
                          <Button variant="contained" color="primary" disabled>Manage</Button>
                        </span>
                      </Tooltip>
                    }
                  </ListItem>
                </List>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Installations);
