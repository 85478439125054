import { AppBar, Toolbar, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import ChangeAccountButton from './ChangeAccountButton';
import Brand from './Brand';
import LoginButton from './LoginButton';
import UserMenu from './UserMenu';

const styles = {
  menu: {
    marginBottom: 20,
  },
};

class Menu extends Component {
  render() {
    return (
      <AppBar position="static" className={this.props.classes.menu}>
        <Toolbar>
          <Brand/>
          <LoginButton/>
          <ChangeAccountButton/>
          <UserMenu/>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(Menu);
