import { Box, Button, TextField } from '@material-ui/core';
import React, { Component } from 'react';
import ToggleWithLabel from '../../common/ToggleWithLabel';

class CommentBox extends Component {
  render() {
    return (
      <Box m={2}>
        <Box>
          <TextField
            id={this.props.id}
            label={this.props.textLabel}
            placeholder={this.props.textPlaceholder}
            multiline
            fullWidth
            minRows={4}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            defaultValue={this.props.textDefaultValue}
            onChange={this.props.textOnChange}
            disabled={this.props.textDisabled}
            inputProps={{ maxLength: 5000 }}
          />
        </Box>
        <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            onClick={this.props.saveButtonOnClick}
            disabled={this.props.saveButtonDisabled}
          >
            Save
          </Button>
          <ToggleWithLabel
            color="primary"
            label={this.props.toggleLabel}
            checked={this.props.toggleChecked}
            disabled={this.props.toggleDisabled}
            onChange={this.props.toggleOnChange}
          />
        </Box>
      </Box>
    )
  }
}

export default CommentBox;
