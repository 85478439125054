import React, { Component } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';

class LoadingSpinner extends Component {
  render() {
    return (
      <Grid container alignItems="center" direction="column">
        <Grid item xs={12}>
          <CircularProgress/>
        </Grid>
      </Grid>
    );
  }
}

export default LoadingSpinner;
