import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Grid, IconButton,
  withStyles
} from '@material-ui/core';
import { ExpandMore, Language } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { CancelToken } from 'axios';
import clsx from 'clsx';
import React, { Component } from 'react';
import axios from '../../../api/axios';
import LoadingSpinner from '../../common/LoadingSpinner';
import ToggleWithLabel from '../../common/ToggleWithLabel';
import Regex from '../regex/Regex';
import CommentBox from './CommentBox';

const styles = theme => ({
  title: {
    fontSize: '1.5rem',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

class GlobalSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadingFailed: false,
      disableEnabling: false,
      maxRepositories: null,
      enabledForAll: false,
      successCommentEnabled: true,
      failureCommentEnabled: true,
      successComment: null,
      failureComment: null,
      branchNameConvention: null,
      pullRequestNameConvention: null,
      commitMessageConvention: null,
      expanded: false,
      disableSuccessCommentToggle: false,
      disableSuccessCommentButtonAndBox: false,
      disableFailureCommentToggle: false,
      disableFailureCommentButtonAndBox: false,
    };

    this.handleEnableForAll = this.handleEnableForAll.bind(this);
    this.handleBranchNameSave = this.handleBranchNameSave.bind(this);
    this.handlePullRequestNameSave = this.handlePullRequestNameSave.bind(this);
    this.handleCommitMessageSave = this.handleCommitMessageSave.bind(this);
    this.handleBranchNameRemove = this.handleBranchNameRemove.bind(this);
    this.handlePullRequestNameRemove = this.handlePullRequestNameRemove.bind(this);
    this.handleCommitMessageRemove = this.handleCommitMessageRemove.bind(this);
    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.handleEnableSuccessComment = this.handleEnableSuccessComment.bind(this);
    this.handleEnableFailureComment = this.handleEnableFailureComment.bind(this);
    this.handleInputChangeSuccessComment = this.handleInputChangeSuccessComment.bind(this);
    this.handleInputChangeFailureComment = this.handleInputChangeFailureComment.bind(this);
    this.handleSaveSuccessComment = this.handleSaveSuccessComment.bind(this);
    this.handleSaveFailureComment = this.handleSaveFailureComment.bind(this);
  }

  async componentDidMount() {
    await this.fetchGlobalSettings(this.props.installationId);
  }

  componentWillUnmount() {
    this.source?.cancel();
    this.setState = () => {
    };
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.installationId !== this.props.installationId) {
      await this.fetchGlobalSettings(this.props.installationId);
    }
  }

  async fetchGlobalSettings(installationId) {
    try {
      this.props.updateMaxRepositories(null);
      this.props.updateEnabledForAll(null);

      this.setState({ loading: true, loadingFailed: false });

      this.source = CancelToken.source();

      const { data } = await axios.get('/installation/' + installationId, {
        cancelToken: this.source.token
      });

      this.setState(data);

      this.props.updateMaxRepositories(data.maxRepositories);
      this.props.updateEnabledForAll(data.enabledForAll);
    } catch (err) {
      this.setState({ loadingFailed: true });
    } finally {
      this.setState({ loading: false });
    }
  }

  async handleEnableForAll() {
    try {
      this.setState({ disableEnabling: true });

      this.source = CancelToken.source();

      const enabledForAll = !this.state.enabledForAll;

      await axios.patch('/installation/' + this.props.installationId, { enabledForAll }, {
        cancelToken: this.source.token
      });

      this.setState({ enabledForAll });

      this.props.updateEnabledForAll(enabledForAll);
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ disableEnabling: false });
    }
  }

  handleBranchNameSave(regex) {
    this.setState({ branchNameConvention: regex });
  }

  handlePullRequestNameSave(regex) {
    this.setState({ pullRequestNameConvention: regex });
  }

  handleCommitMessageSave(regex) {
    this.setState({ commitMessageConvention: regex });
  }

  handleBranchNameRemove() {
    this.setState({ branchNameConvention: null });
  }

  handlePullRequestNameRemove() {
    this.setState({ pullRequestNameConvention: null });
  }

  handleCommitMessageRemove() {
    this.setState({ commitMessageConvention: null });
  }

  handleExpandClick() {
    this.setState({ expanded: !this.state.expanded });
  }

  async handleEnableSuccessComment() {
    try {
      this.setState({ disableSuccessCommentToggle: true });

      this.source = CancelToken.source();

      const successCommentEnabled = !this.state.successCommentEnabled;

      await axios.patch('/installation/' + this.props.installationId, { successCommentEnabled }, {
        cancelToken: this.source.token
      });

      this.setState({ successCommentEnabled });
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ disableSuccessCommentToggle: false });
    }
  }

  async handleEnableFailureComment() {
    try {
      this.setState({ disableFailureCommentToggle: true });

      this.source = CancelToken.source();

      const failureCommentEnabled = !this.state.failureCommentEnabled;

      await axios.patch('/installation/' + this.props.installationId, { failureCommentEnabled }, {
        cancelToken: this.source.token
      });

      this.setState({ failureCommentEnabled });
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ disableFailureCommentToggle: false });
    }
  }

  handleInputChangeSuccessComment(e) {
    this.setState({ successComment: e.target.value });
  }

  async handleSaveSuccessComment() {
    try {
      this.setState({ disableSuccessComment: true });

      this.source = CancelToken.source();

      const successComment = this.state.successComment !== '' ? this.state.successComment : null;

      await axios.patch('/installation/' + this.props.installationId, { successComment }, {
        cancelToken: this.source.token
      });

      this.setState({ successComment });
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ disableSuccessComment: false });
    }
  }

  handleInputChangeFailureComment(e) {
    this.setState({ failureComment: e.target.value });
  }

  async handleSaveFailureComment() {
    try {
      this.setState({ disableFailureComment: true });

      this.source = CancelToken.source();

      const failureComment = this.state.failureComment !== '' ? this.state.failureComment : null;

      await axios.patch('/installation/' + this.props.installationId, { failureComment }, {
        cancelToken: this.source.token
      });

      this.setState({ failureComment });
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ disableFailureComment: false });
    }
  }

  render() {
    if (this.state.loading) return (
      <LoadingSpinner/>
    );

    if (this.state.loadingFailed) return (
      <Grid container>
        <Grid item xs={12}>
          <Alert severity="error">Cannot load global settings. Please contact us.</Alert>
        </Grid>
      </Grid>
    );

    return (
      <Grid container>
        <Grid item xs={12}>
          <Card elevation={2} variant="outlined">
            <CardHeader
              avatar={<Language/>}
              action={<ToggleWithLabel
                color="secondary"
                label="Enable for all repositories"
                onChange={this.handleEnableForAll}
                checked={this.state.enabledForAll}
                disabled={this.state.disableEnabling
                  || (!this.state.enabledForAll && this.state.maxRepositories !== null && this.props.enabledRepositories >= this.state.maxRepositories)
                  || (!this.state.enabledForAll && this.state.maxRepositories !== null && this.props.repositoriesCount > this.state.maxRepositories)
                }
              />}
              title="Global settings"
              titleTypographyProps={{ className: this.props.classes.title }}
            />
            <CardContent>
              <Regex
                variant="branch"
                regex={this.state.branchNameConvention}
                installationId={this.props.installationId}
                onConventionRemove={this.handleBranchNameRemove}
                onConventionSave={this.handleBranchNameSave}
                global
              />
              <Regex
                variant="pr"
                regex={this.state.pullRequestNameConvention}
                installationId={this.props.installationId}
                onConventionRemove={this.handlePullRequestNameRemove}
                onConventionSave={this.handlePullRequestNameSave}
                global
              />
              <Regex
                variant="commit"
                regex={this.state.commitMessageConvention}
                installationId={this.props.installationId}
                onConventionRemove={this.handleCommitMessageRemove}
                onConventionSave={this.handleCommitMessageSave}
                global
              />
            </CardContent>
            <CardActions disableSpacing>
              <IconButton
                className={clsx(this.props.classes.expand, {
                  [this.props.classes.expandOpen]: this.state.expanded,
                })}
                onClick={this.handleExpandClick}
                aria-expanded={this.state.expanded}
                aria-label="show more"
              >
                <ExpandMore/>
              </IconButton>
            </CardActions>
            <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <Divider/>
                <Grid container>
                  <Grid item sm={12} md={6}>
                    <CommentBox
                      id="success-comment"
                      textLabel="Success comment"
                      textPlaceholder="Thank you for following naming conventions! 😻"
                      textDefaultValue={this.state.successComment}
                      textOnChange={this.handleInputChangeSuccessComment}
                      textDisabled={this.state.disableSuccessComment}
                      saveButtonOnClick={this.handleSaveSuccessComment}
                      saveButtonDisabled={this.state.disableSuccessComment}
                      toggleChecked={this.state.successCommentEnabled}
                      toggleDisabled={this.state.disableSuccessCommentToggle}
                      toggleOnChange={this.handleEnableSuccessComment}
                      toggleLabel="Enable success comment"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CommentBox
                      id="failure-comment"
                      textLabel="Failure comment"
                      textPlaceholder="Please follow naming conventions! 😿"
                      textDefaultValue={this.state.failureComment}
                      textOnChange={this.handleInputChangeFailureComment}
                      textDisabled={this.state.disableFailureComment}
                      saveButtonOnClick={this.handleSaveFailureComment}
                      saveButtonDisabled={this.state.disableFailureComment}
                      toggleChecked={this.state.failureCommentEnabled}
                      toggleDisabled={this.state.disableFailureCommentToggle}
                      toggleOnChange={this.handleEnableFailureComment}
                      toggleLabel="Enable failure comment"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Collapse>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(GlobalSettings);
