import React, { Component } from 'react';
import { UserContext } from '../../config/UserContext';
import { IconButton, Menu } from '@material-ui/core';
import GithubAvatar from '../common/GithubAvatar';
import { ExitToApp, GitHub, List } from '@material-ui/icons';
import MenuItemLink from './MenuItemLink';
import config from '../../config';
import Divider from '../common/Divider';

class UserMenu extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      anchor: null,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClick(event) {
    this.setState({ anchor: event.currentTarget });
  }

  handleClose() {
    this.setState({ anchor: null });
  }

  render() {
    if (!this.context.user) return null;

    return (
      <div>
        <IconButton
          aria-label="Menu"
          aria-controls="user-menu"
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <GithubAvatar url={this.context.user.avatarUrl} variant="small"/>
        </IconButton>
        <Menu
          id="user-menu"
          anchorEl={this.state.anchor}
          keepMounted
          open={Boolean(this.state.anchor)}
          onClose={this.handleClose}
        >
          <MenuItemLink onClick={this.handleClose} icon={List} label="Manage installations" to="/"/>
          <MenuItemLink
            onClick={this.handleClose}
            icon={GitHub}
            label="Install integration"
            to={'https://github.com/marketplace/' + config.GITHUB_APP}
          />
          <Divider spacing={1}/>
          <MenuItemLink onClick={this.handleClose} icon={ExitToApp} label="Log out" to="/logout"/>
        </Menu>
      </div>
    );
  }
}

export default UserMenu;
