import axios from 'axios';
import config from '../config';

const instance = axios.create({
  baseURL: config.API_URL,
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');

  if (token) {
    config.headers.Authorization = 'Bearer ' + token;
  }

  return config;
});

export const setupInterceptors = history => {
  instance.interceptors.response.use(res => res, err => {
    const tokenTTL = new Date(parseInt(localStorage.getItem('ttl') || 0));
    const now = new Date();

    if (tokenTTL < now) {
      history.push('/logout');
    }

    return Promise.reject(err);
  });
};

export default instance;
