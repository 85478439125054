import { Container, Snackbar } from '@material-ui/core';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { UserContext } from '../../config/UserContext';
import Divider from '../common/Divider';
import GlobalSettings from './global-settings/GlobalSettings';
import Repositories from './repositories/Repositories';

export class Installation extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      enabledForAll: null,
      repositoriesCount: null,
      enabledRepositories: null,
      maxRepositories: null,
    };

    this.updateRepositoriesCount = this.updateRepositoriesCount.bind(this);
    this.updateEnabledRepositories = this.updateEnabledRepositories.bind(this);
    this.increaseEnabledRepositories = this.increaseEnabledRepositories.bind(this);
    this.decreaseEnabledRepositories = this.decreaseEnabledRepositories.bind(this);
    this.updateMaxRepositories = this.updateMaxRepositories.bind(this);
    this.updateEnabledForAll = this.updateEnabledForAll.bind(this);
  }

  componentDidMount() {
    this.context.changeActiveInstallation(this.props.match.params.installationId);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.installationId !== this.props.match.params.installationId) {
      this.context.changeActiveInstallation(this.props.match.params.installationId);
    }
  }

  updateRepositoriesCount(count) {
    this.setState({ repositoriesCount: count });
  }

  updateEnabledRepositories(count) {
    this.setState({ enabledRepositories: count });
  }

  increaseEnabledRepositories() {
    this.setState(state => ({ enabledRepositories: ++state.enabledRepositories }));
  }

  decreaseEnabledRepositories() {
    this.setState(state => ({ enabledRepositories: --state.enabledRepositories }));
  }

  updateMaxRepositories(count) {
    this.setState({ maxRepositories: count });
  }

  updateEnabledForAll(enabled) {
    this.setState({ enabledForAll: enabled });
  }

  render() {
    const maxRepositories = this.state.maxRepositories !== null ? this.state.maxRepositories : 'unlimited';

    const message = 'Enabled for ' + (
      this.state.enabledForAll
        ? 'all repositories '
        : `${this.state.enabledRepositories} of ${this.state.repositoriesCount} repositories ` +
        `(max ${maxRepositories})`
    );

    return (
      <Container>
        <GlobalSettings
          installationId={this.props.match.params.installationId}
          repositoriesCount={this.state.repositoriesCount}
          enabledRepositories={this.state.enabledRepositories}

          updateMaxRepositories={this.updateMaxRepositories}
          updateEnabledForAll={this.updateEnabledForAll}
        />
        <Divider spacing={3}/>
        <Repositories
          installationId={this.props.match.params.installationId}
          enabledForAll={this.state.enabledForAll}
          enabledRepositories={this.state.enabledRepositories}
          maxRepositories={this.state.maxRepositories}

          updateRepositoriesCount={this.updateRepositoriesCount}
          updateEnabledRepositories={this.updateEnabledRepositories}
          increaseEnabledRepositories={this.increaseEnabledRepositories}
          decreaseEnabledRepositories={this.decreaseEnabledRepositories}
        />
        {this.state.repositoriesCount !== null && <Snackbar
          open={true}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          message={message}
        />}
      </Container>
    );
  }
}

export default withRouter(Installation);
