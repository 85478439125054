import React, { Component } from 'react';
import { Link, MenuItem, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Link as RouterLink } from 'react-router-dom';

const styles = {
  icon: {
    color: grey[500],
    marginRight: 10,
  },
  item: {
    color: grey[900],
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
};

class MenuItemLink extends Component {
  render() {
    const Icon = this.props.icon;

    const item = <MenuItem onClick={this.props.onClick}>
      <div className={this.props.classes.item}>
        <Icon fontSize="small" className={this.props.classes.icon}/>
        {this.props.label}
      </div>
    </MenuItem>;

    if (this.props.to.startsWith('http')) return (
      <Link href={this.props.to} className={this.props.classes.link}>{item}</Link>
    )

    return (
      <RouterLink to={this.props.to} className={this.props.classes.link}>{item}</RouterLink>
    );
  }
}

export default withStyles(styles)(MenuItemLink);
