import { Button, Menu, MenuItem, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { ExpandMore } from '@material-ui/icons';
import { UserContext } from '../../config/UserContext';
import { Link } from 'react-router-dom';
import { grey } from '@material-ui/core/colors';
import GithubAvatar from '../common/GithubAvatar';

const styles = {
  accountName: {
    marginLeft: 10,
  },
  button: {
    textTransform: 'none'
  },
  link: {
    color: grey[900],
    textDecoration: 'none',
  },
  menuItem: {
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '0.9rem'
  },
};

class ChangeAccountButton extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      anchor: null,
    };

    this.handleOpenMenu = this.handleOpenMenu.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);
    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
  }

  handleOpenMenu(event) {
    this.setState({ anchor: event.currentTarget });
  }

  handleCloseMenu() {
    this.setState({ anchor: null });
  }

  handleMenuItemClick(installationId) {
    this.handleCloseMenu();
    this.context.changeActiveInstallation(installationId);
  }

  render() {
    const activeInstallationSelected = this.context.activeInstallation !== null;

    if (this.context.user && this.context.installations.filter(installation => installation.manageable).length > 0) return (
      <div>
        <Button
          aria-controls="installations-menu"
          aria-haspopup="true"
          onClick={this.handleOpenMenu}
          variant="text"
          startIcon={activeInstallationSelected && <GithubAvatar
            url={this.context.activeInstallation.accountAvatarUrl}
            variant="small"
          />}
          endIcon={<ExpandMore/>}
          color="inherit"
          className={this.props.classes.button}
        >
          {!activeInstallationSelected && <span className={this.props.classes.selectedAccount}>
              Select installation
            </span>
          }
          {activeInstallationSelected && <span className={this.props.classes.selectedAccount}>
                {this.context.activeInstallation.accountLogin}
            </span>
          }
        </Button>
        <Menu
          id="installations-menu"
          anchorEl={this.state.anchor}
          keepMounted
          open={Boolean(this.state.anchor)}
          onClose={this.handleCloseMenu}
        >
          {this.context.installations.filter(installation => installation.manageable).map(installation =>
            <Link key={installation.id} to={'/installations/' + installation.id} className={this.props.classes.link}>
              <MenuItem
                onClick={() => this.handleMenuItemClick(installation.id)}
                className={this.props.classes.menuItem}
                selected={activeInstallationSelected && installation.id === this.context.activeInstallation.id}
              >
                <GithubAvatar url={installation.accountAvatarUrl} variant="small"/>
                <span className={this.props.classes.accountName}>{installation.accountLogin}</span>
              </MenuItem>
            </Link>
          )}
        </Menu>
      </div>
    );

    return null;
  }
}

export default withStyles(styles)(ChangeAccountButton);
