import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from '../../api/axios';
import { CancelToken } from 'axios';
import { UserContext } from '../../config/UserContext';
import LoadingSpinner from '../common/LoadingSpinner';
import QueryString from 'query-string'

const pause = false;

class Auth extends Component {
  static contextType = UserContext;

  async componentDidMount() {
    const params = QueryString.parse(this.props.location.search);

    if (pause) {
      return;
    }

    if (this.context.user && ['install', 'update'].includes(params.setup_action)) {
      try {
        this.source = CancelToken.source();

        await axios.post('/auth-refresh', null, {
          cancelToken: this.source.token
        });
      } catch (err) {
        console.log(err);
      }
    }

    if (!this.context.user && params.code) {
      try {
        this.source = CancelToken.source();

        const { data } = await axios.post('/auth', {
          code: params.code
        }, {
          cancelToken: this.source.token
        });

        this.context.login(data);
      } catch (err) {
        console.log(err);
      }
    }

    this.props.history.push('/');
  }

  componentWillUnmount() {
    this.source?.cancel();
  }

  render() {
    return (
      <LoadingSpinner/>
    );
  }
}

export default withRouter(Auth);
