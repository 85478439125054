import React, { Component, useContext } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { setupInterceptors } from '../api/axios';
import { Installation } from '../components/installation/Installation';
import Auth from '../components/screens/Auth';
import Login from '../components/screens/Login';
import Logout from '../components/screens/Logout';
import Main from '../components/screens/Main';
import PageNotFound from '../components/screens/PageNotFound';
import { UserContext } from './UserContext';

const routes = [
  {
    path: '/login',
    component: Login,
    auth: false,
  },
  {
    path: '/logout',
    component: Logout,
    auth: true,
  },
  {
    path: '/auth',
    component: Auth,
    auth: false,
  },
  {
    path: '/installations/:installationId',
    component: Installation,
    auth: true,
  },
  {
    path: '/',
    component: Main,
    auth: true,
  },
  {
    path: '/*',
    component: PageNotFound,
    auth: false,
  },
];

const AuthenticatedRoute = ({ roles, ...props }) => {
  const context = useContext(UserContext);

  if (!context.user) {
    return <Redirect to="/login"/>;
  }

  return <Route {...props}/>;
};

class Router extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    setupInterceptors(props.history);
  }

  render() {
    return (
      <Switch>
        {routes.map((route) => route.auth
          ? <AuthenticatedRoute key={route.path} path={route.path} component={route.component} exact/>
          : <Route key={route.path} path={route.path} component={route.component} exact/>
        )}
      </Switch>
    );
  }
}

export default withRouter(Router);
