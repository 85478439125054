import React, { Component } from 'react';
import { Container } from '@material-ui/core';
import Installations from '../installations/Installations';

class Main extends Component {
  render() {
    return (
      <Container>
        <Installations/>
      </Container>
    );
  }
}

export default Main;
