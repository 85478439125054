import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';

const fontSize = {
  small: '0.7rem',
  normal: '0.8rem',
  large: '1rem',
};

const styles = {
  inherit: {
    color: grey[400],
    fontStyle: 'oblique',
    padding: 2,
  },
  mono: {
    background: grey[200],
    fontFamily: 'Source Code Pro',
    fontSize: props => fontSize[props.fontSize],
    maxWidth: '100%',
    padding: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
};

class Monotype extends Component {
  static defaultProps = {
    fontSize: 'normal',
  }

  render() {
    const standardRegex = <div className={this.props.classes.mono}>{this.props.regex}</div>;
    const inheritFromGlobal = <div className={this.props.classes.inherit}>Inherit from global</div>;
    const noConvention = <div className={this.props.classes.inherit}>No convention (don't validate)</div>;
    const emptyString = <div>
      <span className={this.props.classes.mono}>""</span>
      <span className={this.props.classes.inherit}> (empty string)</span>
    </div>;

    const regex = (regex) => ({
      null: this.props.global ? noConvention : inheritFromGlobal,
      '': emptyString,
    }[regex] || standardRegex);

    return regex(this.props.regex);
  }
}

export default withStyles(styles)(Monotype);
