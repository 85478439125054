export const templates = {
  branch: [
    {
      name: 'Gitflow',
      regex: '(^((develop|master)(?!-.*)))|((release|feature|hotfix|task|story)(-.*))'
    },
    {
      name: 'Kebab-case',
      regex: '^([a-z][a-z0-9]*)(-[a-z0-9]+)*$'
    },
    {
      name: 'Jira identifier + dash + kebab-case',
      regex: '^[A-Z]{1,10}-\\d+-([a-z][a-z0-9]*)(-[a-z0-9]+)*$'
    },
  ],
  pr: [
    {
      name: 'Jira identifier + uppercase first',
      regex: '^[A-Z]{1,10}-\\d+ [A-Z0-9].+'
    }
  ],
  commit: [
    {
      name: 'Uppercase first',
      regex: '^[A-Z0-9]+'
    },
    {
      name: 'Conventional Commits v1',
      regex: '^((build|chore|ci|docs|feat|fix|perf|refactor|revert|style|test|¯\\\\_\\(ツ\\)_/¯)(\\(\\w+\\))?(!)?(: (.*\\s*)*))|(Merge (.*\\s*)*)|(Initial commit$)\n'
    },
  ]
};
