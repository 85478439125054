import { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Person } from '@material-ui/icons';
import { UserContext } from '../../config/UserContext';

const styles = {
  login: {
    color: grey[100],
    textDecoration: 'none',
  }
};

class LoginButton extends Component {
  static contextType = UserContext;

  render() {
    if (this.context.user) return null;

    return (
      <Link to="/login" className={this.props.classes.login}>
        <Button variant="outlined" color="inherit" startIcon={<Person/>}>Log in</Button>
      </Link>
    );
  }
}

export default withStyles(styles)(LoginButton);
