import { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { UserContext } from '../../config/UserContext';

class Logout extends Component {
  static contextType = UserContext;

  componentDidMount() {
    this.context.logout();
  }

  render() {
    return (
      <Redirect to="/"/>
    );
  }
}

export default Logout;
