import CssBaseline from '@material-ui/core/CssBaseline';
import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from '../config/Router';
import { UserContext } from '../config/UserContext';
import Menu from './menu/Menu';

const SECOND = 1000; // milliseconds
const HOUR = 3600 * SECOND;
const REQUEST_SYNC = 30 * SECOND;
const tokenTTLInMilliseconds = () => Date.now() + 24 * HOUR - REQUEST_SYNC;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: localStorage.getItem('token'),
      user: JSON.parse(localStorage.getItem('user')),
      installations: JSON.parse(localStorage.getItem('installations')) || [],
      activeInstallation: null,
    };

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.setAvailableInstallations = this.setAvailableInstallations.bind(this);
    this.changeActiveInstallation = this.changeActiveInstallation.bind(this);
  }

  login({ user, token }) {
    localStorage.setItem('token', token);
    localStorage.setItem('ttl', tokenTTLInMilliseconds().toString());
    localStorage.setItem('user', JSON.stringify(user));

    this.setState({ token, user });
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('ttl');
    localStorage.removeItem('user');
    localStorage.removeItem('installations');

    this.setState({ token: null, user: null });
  }

  setAvailableInstallations(installations) {
    localStorage.setItem('installations', JSON.stringify(installations));

    this.setState({ installations });
  }

  changeActiveInstallation(installationId) {
    const byId = (installation) => installation.id === parseInt(installationId);
    const installation = this.state.installations.filter(byId).pop() || null;

    this.setState({ activeInstallation: installation });
  }

  render() {
    return (
      <UserContext.Provider value={{
        token: this.state.token,
        user: this.state.user,
        installations: this.state.installations,
        activeInstallation: this.state.activeInstallation,
        login: this.login,
        logout: this.logout,
        setAvailableInstallations: this.setAvailableInstallations,
        changeActiveInstallation: this.changeActiveInstallation,
      }}>
        <div>
          <CssBaseline/>
          <BrowserRouter>
            <Menu/>
            <Router/>
          </BrowserRouter>
        </div>
      </UserContext.Provider>
    );
  }
}

export default App;
