import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';

class PageNotFound extends Component {
  render() {
    return (
      <Grid container alignItems="center" direction="column">
        <Grid item xs={12}>
          <Typography variant="h5">404 Page not found</Typography>
        </Grid>
      </Grid>
    );
  }
}

export default PageNotFound;
