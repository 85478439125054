import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const variants = {
  small: 24,
  normal: 36,
  large: 48,
}

const styles = {
  avatar: {
    borderRadius: 50,
    padding: 1,
    height: props => variants[props.variant],
    width: props => variants[props.variant],
  },
  wrapper: {
    alignItems: 'center',
    backgroundColor: grey[50],
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'center',
    height: props => variants[props.variant] + 2,
    width: props => variants[props.variant] + 2,
  },
};


class GithubAvatar extends Component {
  static defaultProps = {
    variant: 'normal',
  }

  render() {
    return (
      <div className={this.props.classes.wrapper}>
        <img src={this.props.url} className={this.props.classes.avatar} alt="Avatar"/>
      </div>
    );
  }
}

export default withStyles(styles)(GithubAvatar);
