const config = {
  prod: {
    API_URL: 'https://api.conventionsbot.io/api',
    GITHUB_APP: 'naming-conventions-bot',
    CLIENT_ID: 'Iv1.2e698afebe69a082',
  },
  stage: {
    API_URL: 'https://stage.api.conventionsbot.io/api',
    GITHUB_APP: 'naming-conventions-bot-stage',
    CLIENT_ID: 'Iv1.23d06544e738e447',
  },
  test: {
    API_URL: 'https://test.api.conventionsbot.io/api',
    GITHUB_APP: 'naming-conventions-bot-test',
    CLIENT_ID: 'Iv1.dec80dd7ac647391',
  },
  dev: {
    // API_URL: 'http://localhost:4000/api',
    API_URL: 'https://dev.api.conventionsbot.io/api',
    GITHUB_APP: 'naming-conventions-bot-dev',
    CLIENT_ID: 'Iv1.f2a7030fcbfda16a',
  },
}

export default config[process.env.REACT_APP_TARGET || 'dev'];
