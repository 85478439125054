import { Box, Link, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import grey from '@material-ui/core/colors/grey';
import { ReactComponent as BranchIcon } from './svg/branch.svg';
import { ReactComponent as CommitIcon } from './svg/commit.svg';
import { ReactComponent as PullRequestIcon } from './svg/pull-request.svg';
import DialogBox from './DialogBox';
import Monotype from './Monotype';

const styles = {
  box: {
    alignItems: 'center',
    display: 'flex',
    height: '1.5rem',
  },
  icon: {
    marginRight: 8,
    marginTop: 4,
  },
  label: {
    marginRight: 8,
  },
  link: {
    marginLeft: 'auto',
    paddingLeft: 8,
  },
  regex: {
    overflow: 'hidden',
  },
  svg: {
    fill: grey[500],
    height: '1rem',
    width: '1rem',
  },
};

class Regex extends Component {
  static conventionLabels = {
    branch: 'Branch',
    commit: 'Commit',
    pr: 'Pull request',
  };

  icons = {
    branch: <BranchIcon className={this.props.classes.svg}/>,
    commit: <CommitIcon className={this.props.classes.svg}/>,
    pr: <PullRequestIcon className={this.props.classes.svg}/>,
  };

  constructor(props) {
    super(props);

    this.state = {
      openDialog: false,
    };

    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
  }

  componentWillUnmount() {
    this.source?.cancel();
    this.setState = () => {
    };
  }

  handleOpenDialog(e) {
    e.preventDefault();

    this.setState({ openDialog: true });
  }

  handleCloseDialog() {
    this.setState({ openDialog: false });
  }

  render() {
    const repository = this.props.global ? '' : '/repositories/' + this.props.repositoryId;
    const href = '/installation/' + this.props.installationId + repository + '/' + this.props.variant;

    return (
      <Box className={this.props.classes.box}>
        <div className={this.props.classes.icon}>
          {this.icons[this.props.variant]}
        </div>
        <div className={this.props.classes.label}>
          {Regex.conventionLabels[this.props.variant]}:
        </div>
        <div className={this.props.classes.regex}>
          <Monotype regex={this.props.regex} global={this.props.global}/>
        </div>
        <div className={this.props.classes.link}>
          <Link href={href} onClick={this.handleOpenDialog} variant="body2">Edit</Link>
        </div>
        <DialogBox
          regex={this.props.regex}
          open={this.state.openDialog}
          variant={this.props.variant}
          global={this.props.global}
          repositoryId={this.props.repositoryId}
          installationId={this.props.installationId}
          onDialogClose={this.handleCloseDialog}
          onConventionRemove={this.props.onConventionRemove}
          onConventionSave={this.props.onConventionSave}
        />
      </Box>
    );
  }
}

export default withStyles(styles)(Regex);
