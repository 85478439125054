import React, { Component } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Link,
  Typography,
  withStyles
} from '@material-ui/core';
import Logo from '../common/Logo';
import { GitHub } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import { UserContext } from '../../config/UserContext';
import { Redirect } from 'react-router-dom';
import LoadingSpinner from '../common/LoadingSpinner';
import config from '../../config';

const styles = {
  actions: {
    justifyContent: 'center',
  },
  agree: {
    color: grey[500],
    marginTop: 10,
  },
  card: {
    padding: 20,
  },
  content: {
    marginTop: 30,
    marginBottom: 10,
  },
  link: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  logo: {
    maxWidth: 100,
  },
};

class Login extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.handleLogin = this.handleLogin.bind(this);
  }

  handleLogin() {
    this.setState({ loading: true });
  }

  render() {
    if (this.context.user) return (
      <Redirect to="/"/>
    );

    return (
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={4}>
                <Card className={this.props.classes.card}>
                  <CardContent>
                    <Grid container direction="column" alignItems="center">
                      <Logo className={this.props.classes.logo}/>
                      <Typography variant="h5" className={this.props.classes.content}>
                        Please log in to continue
                      </Typography>
                    </Grid>
                  </CardContent>
                  <CardActions className={this.props.classes.actions}>
                    {this.state.loading && <LoadingSpinner/>}
                    {!this.state.loading && <Link
                      href={'https://github.com/login/oauth/authorize?client_id=' + config.CLIENT_ID}
                      onClick={this.handleLogin}
                      className={this.props.classes.link}
                    >
                      <Button variant="contained" startIcon={<GitHub/>} size="large" color="primary">
                        Log in with Github
                      </Button>
                    </Link>
                    }
                  </CardActions>
                </Card>
                <Typography variant="body2" className={this.props.classes.agree}>
                  By signing in, you agree to our Terms of Service and Privacy Policy.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withStyles(styles)(Login);
