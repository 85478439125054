import React, { Component } from 'react';
import logo from './img/logo.png';

class Logo extends Component {
  render() {
    return (
      <img src={logo} alt="Logo" className={this.props.className}/>
    );
  }
}

export default Logo;
