import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../common/Logo';
import { grey } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  logo: {
    maxWidth: 40,
    marginRight: 10,
  },
  brand: {
    color: grey[100],
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    marginRight: 'auto',
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
});

class Brand extends Component {
  render() {
    return (
      <Link to="/" className={this.props.classes.brand}>
        <Logo className={this.props.classes.logo}/>
        Conventionsbot
      </Link>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Brand);
