import React, { Component } from 'react';
import { Divider as MaterialUiDivider, withStyles } from '@material-ui/core';

const styles = theme => ({
  divider: {
    marginTop: props => theme.spacing(props.spacing),
    marginBottom: props => theme.spacing(props.spacing),
  },
});

class Divider extends Component {
  static defaultProps = {
    spacing: 2,
  }

  render() {
    return (
      <MaterialUiDivider className={this.props.classes.divider} light/>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Divider);
