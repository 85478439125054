import React, { Component } from 'react';
import { FormControl, FormControlLabel, FormGroup, Switch } from '@material-ui/core';

class ToggleWithLabel extends Component {
  render() {
    return (
      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            value="start"
            control={<Switch
              color={this.props.color}
              checked={this.props.checked}
              disabled={this.props.disabled}
              onChange={this.props.onChange}
              name="enabled"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />}
            label={this.props.label}
            labelPlacement="start"
          />
        </FormGroup>
      </FormControl>
    );
  }
}

export default ToggleWithLabel;
