import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { CancelToken } from 'axios';
import React, { Component } from 'react';
import axios from '../../../api/axios';
import { templates } from '../../../templates';
import Monotype from './Monotype';

const styles = {
  description: {
    fontWeight: 700,
    marginBottom: 2,
  },
  menu: {
    maxWidth: 600,
  },
  menuItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  remove: {
    color: red[700],
  },
};

class DialogBox extends Component {
  static conventionFieldNames = {
    branch: 'branchNameConvention',
    pr: 'pullRequestNameConvention',
    commit: 'commitMessageConvention',
  };

  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      error: false,
      errorText: null,
      anchor: null,
      regex: props.regex,
    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleTemplateSelect = this.handleTemplateSelect.bind(this);
    this.handleOpenMenu = this.handleOpenMenu.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);
    this.handleRemoveConvention = this.handleRemoveConvention.bind(this);
    this.handleSaveConvention = this.handleSaveConvention.bind(this);
  }

  async handleKeyPress(event) {
    if (event.key === 'Enter') {
      await this.handleSaveConvention();
    }
  }

  handleInputChange(e) {
    this.setState({ regex: e.target.value });
  }

  handleTemplateSelect(event, item) {
    this.handleCloseMenu();
    this.setState({ regex: item });
  }

  handleOpenMenu(event) {
    this.setState({ anchor: event.currentTarget });
  }

  handleCloseMenu() {
    this.setState({ anchor: null });
  }

  async handleRemoveConvention() {
    await this.setState({ regex: null });
    await this.saveConvention();
  }

  async handleSaveConvention() {
    if (this.state.regex === null) {
      await this.setState({ regex: '' });
    }

    await this.saveConvention();
  }

  async saveConvention() {
    try {
      this.setState({ disabled: true, error: false, errorText: null });

      this.source = CancelToken.source();

      const fieldName = DialogBox.conventionFieldNames[this.props.variant];
      let url = '/installation/' + this.props.installationId;

      if (!this.props.global) {
        url += '/repository/' + this.props.repositoryId;
      }

      await axios.patch(url, {
        [fieldName]: this.state.regex
      }, {
        cancelToken: this.source.token
      });

      this.props.onConventionSave(this.state.regex);
      this.props.onDialogClose();
    } catch (err) {
      this.setState({ error: true, errorText: err.response.data?.message || 'Unknown error occurred' });
    } finally {
      this.setState({ disabled: false });
    }
  }

  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.onDialogClose} aria-labelledby="form-dialog-title" fullWidth={true}>
        <DialogTitle id="form-dialog-title">Modify naming convention</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide naming convention using regular expression (PCRE2).
          </DialogContentText>
          <TextField
            autoFocus
            value={this.state.regex || ''}
            margin="dense"
            id="regex-input"
            label="Regular expression"
            fullWidth
            error={this.state.error}
            helperText={this.state.errorText}
            onChange={this.handleInputChange}
            onKeyPress={this.handleKeyPress}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={this.state.disabled} onClick={this.handleRemoveConvention} className={this.props.classes.remove}>
            Remove
          </Button>
          <Button disabled={this.state.disabled} onClick={this.handleOpenMenu} color="primary">
            Templates
          </Button>
          <div style={{ flex: '1 0 0' }}/>
          <Button disabled={this.state.disabled} onClick={this.props.onDialogClose} color="primary">
            Cancel
          </Button>
          <Button disabled={this.state.disabled} onClick={this.handleSaveConvention} color="primary">
            Save
          </Button>
        </DialogActions>
        <Menu
          id="templates-menu"
          anchorEl={this.state.anchor}
          keepMounted
          open={Boolean(this.state.anchor)}
          onClose={this.handleCloseMenu}
          className={this.props.classes.menu}
        >
          {templates[this.props.variant].map(item =>
            <MenuItem
              key={item}
              onClick={(event) => this.handleTemplateSelect(event, item.regex)}
              className={this.props.classes.menuItem}
            >
              <Typography variant="caption" className={this.props.classes.description}>
                {item.name}
              </Typography>
              <Monotype regex={item.regex} fontSize="small"/>
            </MenuItem>
          )}
        </Menu>
      </Dialog>
    );
  }
}

export default withStyles(styles)(DialogBox);
