import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CancelToken } from 'axios';
import { Component } from 'react';
import axios from '../../../api/axios';
import LoadingSpinner from '../../common/LoadingSpinner';
import Repository from './Repository';

class Repositories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadingFailed: false,
      repositories: [],
    };
  }

  async componentDidMount() {
    await this.fetchRepositories(this.props.installationId);
  }

  componentWillUnmount() {
    this.source?.cancel();
    this.setState = () => {
    };
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.installationId !== this.props.installationId) {
      await this.fetchRepositories(this.props.installationId);
    }
  }

  async fetchRepositories(installationId) {
    try {
      this.props.updateRepositoriesCount(null);
      this.props.updateEnabledRepositories(null);

      this.setState({ loading: true, loadingFailed: false });

      this.source = CancelToken.source();

      const { data } = await axios.get('/installation/' + installationId + '/repository', {
        cancelToken: this.source.token
      });

      this.setState(data);

      this.props.updateRepositoriesCount(data.repositories.length);
      this.props.updateEnabledRepositories(data.repositories.filter(item => item.enabled).length);
    } catch (err) {
      this.setState({ loadingFailed: true });
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    if (this.state.loading) return (
      <LoadingSpinner/>
    );

    if (this.state.loadingFailed) return (
      <Grid container>
        <Grid item xs={12}>
          <Alert severity="error">Cannot load repositories. Please contact us.</Alert>
        </Grid>
      </Grid>
    );

    if (this.state.repositories.length === 0) return (
      <Grid container>
        <Grid item xs={12}>
          <Alert severity="info">
            No repositories found, or you don't have sufficient permissions to manage any repositories.
          </Alert>
        </Grid>
      </Grid>
    );

    return (
      <Grid container spacing={2}>
        {this.state.repositories.map(item =>
          <Repository
            key={'Repository' + item.id}
            id={item.id}
            installationId={this.props.installationId}
            name={item.name}
            enabled={item.enabled}
            branchNameConvention={item.branchNameConvention}
            pullRequestNameConvention={item.pullRequestNameConvention}
            commitMessageConvention={item.commitMessageConvention}

            enabledForAll={this.props.enabledForAll}
            enabledRepositories={this.props.enabledRepositories}
            maxRepositories={this.props.maxRepositories}
            increaseEnabledRepositories={this.props.increaseEnabledRepositories}
            decreaseEnabledRepositories={this.props.decreaseEnabledRepositories}
          />
        )}
      </Grid>
    );
  }
}

export default Repositories;
