import React from 'react';

const UserContext = React.createContext({
  token: null,
  user: null,
  installations: null,
  activeInstallation: null,
});

export { UserContext };
