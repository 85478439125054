import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { CancelToken } from 'axios';
import React, { Component } from 'react';
import axios from '../../../api/axios';
import ToggleWithLabel from '../../common/ToggleWithLabel';
import Regex from '../regex/Regex';

class Repository extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disableEnabling: false,
      enabled: props.enabled,
      branchNameConvention: props.branchNameConvention,
      pullRequestNameConvention: props.pullRequestNameConvention,
      commitMessageConvention: props.commitMessageConvention
    };

    this.handleEnableRepository = this.handleEnableRepository.bind(this);
    this.handleBranchNameSave = this.handleBranchNameSave.bind(this);
    this.handlePullRequestNameSave = this.handlePullRequestNameSave.bind(this);
    this.handleCommitMessageSave = this.handleCommitMessageSave.bind(this);
    this.handleBranchNameRemove = this.handleBranchNameRemove.bind(this);
    this.handlePullRequestNameRemove = this.handlePullRequestNameRemove.bind(this);
    this.handleCommitMessageRemove = this.handleCommitMessageRemove.bind(this);
  }

  componentWillUnmount() {
    this.source?.cancel();
    this.setState = () => {
    };
  }

  async handleEnableRepository() {
    try {
      this.setState({ disableEnabling: true });

      this.source = CancelToken.source();

      await axios.patch('/installation/' + this.props.installationId + '/repository/' + this.props.id, {
        enabled: !this.state.enabled
      }, {
        cancelToken: this.source.token
      });

      await this.setState(state => ({ enabled: !state.enabled }));

      if (this.state.enabled) {
        this.props.increaseEnabledRepositories();
      } else {
        this.props.decreaseEnabledRepositories();
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ disableEnabling: false });
    }
  }

  handleBranchNameSave(regex) {
    this.setState({ branchNameConvention: regex });
  }

  handlePullRequestNameSave(regex) {
    this.setState({ pullRequestNameConvention: regex });
  }

  handleCommitMessageSave(regex) {
    this.setState({ commitMessageConvention: regex });
  }

  handleBranchNameRemove() {
    this.setState({ branchNameConvention: null });
  }

  handlePullRequestNameRemove() {
    this.setState({ pullRequestNameConvention: null });
  }

  handleCommitMessageRemove() {
    this.setState({ commitMessageConvention: null });
  }

  render() {
    return (
      <Grid item xs={12} sm={6}>
        <Card elevation={2} variant="outlined">
          <CardHeader
            action={<ToggleWithLabel
              color="primary"
              label="Enable"
              onChange={this.handleEnableRepository}
              checked={this.state.enabled}
              disabled={this.state.disableEnabling
                || this.props.enabledForAll
                || (!this.state.enabled && this.props.maxRepositories !== null && this.props.enabledRepositories >= this.props.maxRepositories)
              }
            />}
            title={this.props.name}
          />
          <CardContent>
            <Regex
              variant="branch"
              regex={this.state.branchNameConvention}
              repositoryId={this.props.id}
              installationId={this.props.installationId}
              onConventionRemove={this.handleBranchNameRemove}
              onConventionSave={this.handleBranchNameSave}
            />
            <Regex
              variant="pr"
              regex={this.state.pullRequestNameConvention}
              repositoryId={this.props.id}
              installationId={this.props.installationId}
              onConventionRemove={this.handlePullRequestNameRemove}
              onConventionSave={this.handlePullRequestNameSave}
            />
            <Regex
              variant="commit"
              regex={this.state.commitMessageConvention}
              repositoryId={this.props.id}
              installationId={this.props.installationId}
              onConventionRemove={this.handleCommitMessageRemove}
              onConventionSave={this.handleCommitMessageSave}
            />
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

export default Repository;
